<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.postDetails.title") }}</h3>
    <b-row class="no-gutters ml-n3">
      <b-col class="col-12 col-sm-4 pl-3">
        <div class="font-weight-bold">{{ `${$t("condominium")}:` }}</div>
        <div>{{ condominiumName }}</div>
        <div>{{ condominiumAddress }}</div>
        <div>{{ condominiumCountry }}</div>
      </b-col>
      <b-col class="col-12 col-sm-4 pl-3">
        <div class="font-weight-bold">{{ `${$t("author")}:` }}</div>
        <div>{{ authorName }}</div>
        <div class="font-weight-bold">
          {{ `${$t("roleInCondominium")}:` }}
        </div>
        <div>
          {{
            $is1Board
              ? authorRoleInCondominium
              : $t(`userRoles.${authorRoleInCondominium}`)
          }}
        </div>
      </b-col>
      <b-col class="col-12 col-sm-4 pl-3">
        <div class="font-weight-bold">{{ `${$t("date")}:` }}</div>
        <div class="text-capitalize">
          {{ post.createdAt | date("DD MMMM YYYY, HH:mm") }}
        </div>
      </b-col>
      <b-col v-if="post.reported" class="col-12 col-sm-6 col-md-3 ml-3 pt-4">
        <div class="font-weight-bold" style="">
          <b-icon style="width: 12px; color: red" icon="exclamation-triangle" />
          {{ $t("reported") }}
        </div>
        <div style="line-height: 1.1em">
          {{ $t("unreportMessage") }}
        </div>

        <b-button
          size="sm"
          variant="red"
          class="text-white mt-2"
          @click="undoReport(post)"
        >
          {{ $t("button.undoReport") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="no-gutters ml-n3 mt-5">
      <b-col class="col-12 pl-3" :class="{ 'col-sm-6': hasPhotos }">
        <div class="font-weight-bold">{{ `${$t("postContent")}:` }}</div>
        {{ post.body }}
      </b-col>
      <b-col v-if="hasPhotos" class="col-12 col-sm-6 pl-3">
        <gallery :photos="photoAsArray" />
      </b-col>
    </b-row>

    <div v-if="hasPostDocuments" class="mt-5">
      <div class="font-weight-bold mb-2">{{ `${$t("file")}:` }}</div>
      <template v-for="document in postDocuments">
        <post-file-list-item :key="document._id" :file="document" />
      </template>
    </div>

    <div class="mt-5">
      <p class="font-weight-bold">{{ $t("comments") }}</p>
      <post-user-table
        :items="comments"
        :fields="commentFields"
        :actions="commentActions"
        :sort-by.sync="commentSortBy"
        :sort-desc.sync="commentSortDesc"
        @undoReport="undoReportCommentModal"
      />
    </div>

    <div class="mt-5">
      <p class="font-weight-bold">{{ $t("likes") }}</p>
      <post-user-table
        :items="likes"
        :fields="likeFields"
        :actions="likeActions"
        :sort-by.sync="likeSortBy"
        :sort-desc.sync="likeSortDesc"
      />
    </div>

    <b-row class="no-gutters justify-content-end mt-5">
      <b-col class="col-auto">
        <b-button
          variant="orange"
          class="text-white"
          block
          @click="openDeletePostConfirmationModal"
        >
          {{ $t("button.deletePost") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import userMixin from "./../mixins/userMixin";

import Gallery from "./../components/Base/BaseGallery";
import PostUserTable from "./../components/Post/PostUserTable";
import PostFileListItem from "../components/Post/FileListItem";
import { appMixin } from "../mixins";
export default {
  components: {
    Gallery,
    PostUserTable,
    PostFileListItem
  },
  props: {
    postId: {
      type: String,
      default: ""
    },
    condominiumId: {
      type: String,
      default: ""
    }
  },
  mixins: [userMixin, appMixin],
  data() {
    return {
      commentFields: [
        {
          key: "author",
          label: "author",
          sort: true,
          thStyle: { width: "25%" }
        },
        {
          key: "createdAt",
          label: "date",
          sort: true,
          thStyle: { width: "20%" }
        },
        {
          key: "comment",
          label: "comment",
          sort: false,
          thStyle: { width: "30%" }
        },
        {
          key: "reported",
          label: "reported",
          thStyle: { width: "5%" },
          sort: false
        },
        { key: "actions", label: "", thStyle: { width: "20%" } }
      ],
      commentActions: [
        {
          label: "button.delete",
          variant: "orange",
          callbackFunc: this.openDeleteCommentConfirmationModal
        }
      ],
      likeFields: [
        {
          key: "author",
          label: "user",
          sort: true,
          thStyle: { width: "30%" }
        },
        {
          key: "createdAt",
          label: "date",
          sort: true,
          thStyle: { width: "30%" }
        },
        {
          key: "comments",
          label: "",
          sort: false,
          thStyle: { width: "30%" }
        },
        { key: "actions", label: "", thStyle: { width: "10%" } }
      ],
      likeActions: []
    };
  },
  computed: {
    post() {
      return this.$store.getters["post/getCurrentPost"];
    },
    condominiumName() {
      return this.post.condominiumId.name;
    },
    condominiumAddress() {
      return `${this.post.condominiumId.address} ${this.post.condominiumId.streetNumber}`;
    },
    condominiumCountry() {
      return `${this.post.condominiumId.zip} ${this.post.condominiumId.city} (${this.post.condominiumId.province})`;
    },
    authorName() {
      if (this.post.ownerId)
        return this.$_getUserFullName(this.post.ownerId.profile);
      else return "-";
    },
    authorRoleInCondominium() {
      return (
        this.post.ownerId.condominiums.find(c => {
          return c.condominiumId === this.condominiumId;
        })?.role || "-"
      );
    },
    hasPhotos() {
      return this.post.photos || this.post.photo;
    },
    photoAsArray() {
      if (this.$is1Board) {
        return this.post.photos.length
          ? this.post.photos.map(p => ({ thumb: p.url, src: p.url }))
          : [];
      }
      return [
        {
          thumb: this.post.photo.url,
          src: this.post.photo.url
        }
      ];
    },
    comments() {
      return this.post.comments
        .filter(comment => comment.ownerId)
        .map(comment => {
          return {
            id: comment._id,
            avatar: this.$_getUserProfilePhoto(comment.ownerId.profile),
            author: this.$_getUserFullName(comment.ownerId.profile),
            createdAt: comment.date,
            comment: comment.body,
            reported: comment.reported
          };
        });
    },
    likes() {
      return this.post.likes
        .filter(likes => likes.ownerId)
        .map(comment => {
          return {
            id: comment._id,
            avatar: this.$_getUserProfilePhoto(comment.ownerId.profile),
            author: this.$_getUserFullName(comment.ownerId.profile),
            createdAt: comment.date
          };
        });
    },
    commentSortBy: {
      get() {
        return this.$store.getters["post/getCommentSortBy"];
      },
      set(value) {
        this.$store.commit("post/SET_COMMENT_SORT_BY", value);
      }
    },
    commentSortDesc: {
      get() {
        return this.$store.getters["post/getCommentSortDesc"];
      },
      set(value) {
        this.$store.commit("post/SET_COMMENT_SORT_DESC", value);
      }
    },
    likeSortBy: {
      get() {
        return this.$store.getters["post/getLikeSortBy"];
      },
      set(value) {
        this.$store.commit("post/SET_LIKE_SORT_BY", value);
      }
    },
    likeSortDesc: {
      get() {
        return this.$store.getters["post/getLikeSortDesc"];
      },
      set(value) {
        this.$store.commit("post/SET_LIKE_SORT_DESC", value);
      }
    },
    postDocuments() {
      return Array.isArray(this.post.documents) ? this.post.documents : [];
    },
    hasPostDocuments() {
      return this.postDocuments.length > 0;
    }
  },
  methods: {
    openDeleteCommentConfirmationModal(commentId) {
      this.$store.commit("confirm/SET_CONFIRM", {
        confirmBtnCallback: this.deleteComment,
        confirmBtnParams: {
          postId: this.post._id,
          condominiumId: this.post.condominiumId._id,
          commentId
        },
        type: "DELETE"
      });
    },
    openDeletePostConfirmationModal() {
      this.$store.commit("confirm/SET_CONFIRM", {
        confirmBtnCallback: this.deletePost,
        confirmBtnParams: {
          postId: this.post._id,
          condominiumId: this.post.condominiumId._id
        },
        type: "DELETE"
      });
    },
    undoReportCommentModal(comment) {
      this.$store.commit("confirm/SET_CONFIRM", {
        confirmBtnCallback: this.undoReportComment,
        confirmBtnParams: {
          postId: this.post._id,
          condominiumId: this.post.condominiumId._id,
          commentId: comment.id
        },
        type: "PROCEED"
      });
    },
    async deletePost({ postId, condominiumId }) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("post/deletePost", {
          postId,
          condominiumId
        });

        this.$router.push({
          name: "Posts"
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async deleteComment({ postId, condominiumId, commentId }) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("post/deleteComment", {
          postId,
          condominiumId,
          commentId
        });

        await this.$store.dispatch("post/retrievePost", {
          postId,
          condominiumId
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async undoReport() {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        await this.$store.dispatch("post/removePostReport", {
          condominiumId: this.post.condominiumId._id,
          postId: this.post._id
        });
        this.$router.go(0);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async undoReportComment({ postId, condominiumId, commentId }) {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        await this.$store.dispatch("post/removeReportPostComment", {
          condominiumId,
          postId,
          commentId
        });
        await this.$store.dispatch("post/retrievePost", {
          postId,
          condominiumId
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
