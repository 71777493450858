<template>
  <b-row class="no-gutters flex-nowrap align-content-center mb-2">
    <b-col class="col-auto">
      <b-button size="sm" class="mr-3" @click="readFile">
        <b-icon icon="file-earmark-text" color="white" />
      </b-button>
    </b-col>
    <b-col class="col-auto">
      {{ file.filename }}
    </b-col>
  </b-row>
</template>

<script>
import { getValidUrl } from "../../helpers/utils";

export default {
  props: {
    file: {
      type: Object
    }
  },
  methods: {
    readFile() {
      window.open(getValidUrl(this.file.url), "_blank");
    }
  }
};
</script>
